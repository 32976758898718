<template>
  <b-card class="guide text-justify max-w-lg">
    <div class="font-large-4 text-primary text-center hortilio-title">
      Hortilio
    </div>
    <p>
      L’application Hortilio vous aide à nourrir votre jardin ou votre potager avec le plus riche terreau qui soit :
      celui de la biodiversité.
    </p>

    <div class="d-flex justify-content-center">
      <img
          alt="Icone de feuille"
          src="@/assets/images/icons/plants/default_leaf.svg"
          style="height: 35px;">
    </div>

    <p>
      Notre but, en développant cette application, est de vous fournir, à vous et à votre jardin, un environnement
      épanouissant
      en vous donnant accès un savoir encyclopédique solide, en favorisant le partage d’expérience des utilisateurs
      et
      en vous connectant aux services proposés par votre collectivité locale.
    </p>
    <div class="d-flex justify-content-center">
      <img
          alt="Icone de feuille"
          src="@/assets/images/icons/other/feedback.svg"
          style="height: 35px;">
    </div>
    <p>
      Mais de même que les plantes ont besoin de la biodiversité du vivant pour s’épanouir, cette application a besoin
      d’une diversité de contributions pour exister et grandir. S’agissant d’une première version, les différents
      modules qui la composent sont encore largement en évolution, et vos retours sont essentiels pour orienter son
      développement.
    </p>
    <p>
      Il s’agit, au travers de ces modules, de faciliter la définition et la maintenance d’un jardin cohérent, adapté
      à
      vos aspirations comme à son environnement.
    </p>

    <TextBlock
        v-for="(module, index) in modules"
        :key="index"
        class="mb-3">
      <div slot="title" class="d-flex align-items-center">
        {{ module.title }}
        <b-button
            class="p-50"
            pill
            size="sm"
            variant="flat-dark"
            @click="$router.push({name: 'jayaccueil', hash: '#' + module.path})">
          <feather-icon
              icon="ExternalLinkIcon"
              size="16"/>
        </b-button>
      </div>
      <feather-icon
          slot="titleSpace"
          :icon="module.icon + 'Icon'"
          class="mr-1 align-middle"
          size="20"/>
      <div> {{ module.text }}</div>
      <b-list-group flush>
        <b-list-group-item>
          <h5 class="text-primary mb-0">Disponible</h5>
        </b-list-group-item>
        <b-list-group-item
            v-for="(fct, index) in module.functions.filter(f => f.implemented)"
            :key="index"
            class="font-small-3 p-25 d-flex justify-content-between align-items-center">
          <span>{{ fct.title }}</span>
          <feather-icon
              slot="titleSpace"
              class="text-primary ml-25"
              icon="CheckIcon"
              size="16"
              style="min-width: 16px;"/>
        </b-list-group-item>
        <b-list-group-item>
          <h5 class="text-secondary mb-0">A venir</h5>
        </b-list-group-item>
        <b-list-group-item
            v-for="(fct, index) in module.functions.filter(f=>!f.implemented)"
            :key="index"
            class="font-small-3 p-25 d-flex justify-content-between align-items-center">
          <span>{{ fct.title }}</span>
          <feather-icon
              slot="titleSpace"
              class="text-secondary ml-25"
              icon="LoaderIcon"
              size="16"
              style="min-width: 16px;"/>
        </b-list-group-item>
      </b-list-group>
    </TextBlock>
  </b-card>
</template>

<script>
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {BButton, BCard, BListGroup, BListGroupItem} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BCard,
    TextBlock
  },
  data() {
    return {
      modules: [
        {
          title: 'Mon Jardin',
          path: 'jardin',
          text: '« Mon Jardin » est le module central. Il permet de gérer les plantes que vous avez choisies pour votre jardin ou votre potager et vous offre d’en évaluer le niveau de biodiversité et donc la résilience.',
          icon: 'Home',
          functions: [
            {
              title: 'Regrouper les fiches utiles pour les plantes de son jardin',
              implemented: true
            },
            {
              title: 'Evaluer son score de biodiversité',
              implemented: true
            },
            {
              title: 'Accéder à des fiches pratiques pertinentes pour favoriser la biodiversité',
              implemented: true
            },
            {
              title: 'Concevoir un potager prenant en compte les associations bénéfiques entre espèces',
              implemented: true
            },
            {
              title: 'Proposer un placement des plantes potagère selon le principe du compagnonage végétal',
              implemented: true
            },
            {
              title: 'Recevoir des rappels personnalisés sur l\'entretien du jardin',
              implemented: true
            },
            {
              title: 'Identifier une plante à partir de sa photo (Pl@ntNET)',
              implemented: true
            },
            {
              title: 'Utiliser le retour d’expérience des utilisateurs pour améliorer les conseils',
              implemented: false
            },
            {
              title: 'Prendre en compte les données locales pour améliorer les conseils : type de sol, espèces' +
                  ' présentes, climat et pratiques',
              implemented: false
            },
          ]
        },
        {
          title: 'Autour de moi',
          path: 'autour-de-moi',
          text: 'Le module « Autour de moi » vous informe des évènements et lieuxen lien avec le ' +
              'jardinage et vous donne accès aux ' +
              'informations propres à votre collectivité territoriale.',
          icon: 'Calendar',
          functions: [
            {
              title: 'S\'abonner à des collectivités territoriales',
              implemented: true
            },
            {
              title: 'Accéder aux informations liées au territoire',
              implemented: true
            },
            {
              title: 'Visualiser les évènements et les lieux des collectivités auxquelles vous êtes abonné',
              implemented: true
            },
            {
              title: 'Recevoir des conseils adaptés à votre zone géographique',
              implemented: false
            }
          ]
        },
        {
          title: 'Coup de pousse',
          path: 'forum',
          text: 'Le module « Coup de pousse » vous connecte aux autres utilisateurs en vous permettant de poser comme de répondre à des questions et de partager vos expériences.',
          icon: 'MessageCircle',
          functions: [
            {
              title: 'Poser et répondre à des questions',
              implemented: true
            },
            {
              title: 'Solliciter la communauté pour identifier des plantes',
              implemented: true
            },
            {
              title: 'Prendre des notes et programmer des rappels',
              implemented: true
            },
          ]
        },
        {
          title: 'Encyclopédie',
          path: 'encyclopedie',
          text: 'Pour vous aider à définir votre jardin, le module appelé « Encyclopédie » vous donne accès à une riche base de données vous permettant non seulement de trouver les plantes qui correspondent le mieux à vos aspirations, mais aussi celles qui fonctionnent en symbiose.',
          icon: 'BookOpen',
          functions: [
            {
              title: 'Intérêt des plantes',
              implemented: true
            },
            {
              title: 'Conditions de développement optimales des plantes',
              implemented: true
            },
            {
              title: 'Plantes locales ou envahissantes',
              implemented: true
            },
            {
              title: 'Plantes adaptées aux balcons',
              implemented: true
            },
            {
              title: 'Périodes d\'entretiens: Tailles, Récoltes et Floraisons',
              implemented: true
            },
            {
              title: 'Bienfaits sur la biodiversité',
              implemented: true
            },
            {
              title: 'Compagnonage végétal pour les plantes potagères',
              implemented: true
            },
            {
              title: 'Informations sur la gestion de culture (semis, sols favoris, repiquage ...)',
              implemented: true
            },
            {
              title: 'Harmonisation de la base de données selon le référentiel TaxREF de l\'INPN',
              implemented: false
            },
            {
              title: 'Fiches d\'informations sur la faune des jardin',
              implemented: false
            },
          ]
        },
      ]
    }
  },
}
</script>

<style lang="scss">
.guide {
  p {
    /*text-indent: 15px;*/
  }
}
</style>
